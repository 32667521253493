import { createReducer, on } from '@ngrx/store';
import { getKey } from '../../utils/get-key';
import { ongoingOrdersActions } from '../actions/ongoing-orders.actions';
import { OngoingOrder } from '../models';

export interface Result {
    items: OngoingOrder[] | null;
    totalItems: number | null;
}

export interface OngoingOrdersState {
    loaded: null | boolean;
    loading: boolean;
    results: Map<string, Result>;
    currentResult: null | Result;
}

export const initialState: OngoingOrdersState = {
    loaded: null,
    loading: false,
    results: new Map(),
    currentResult: null,
};

export const ongoingOrdersReducer = createReducer(
    initialState,

    on(ongoingOrdersActions.getOngoingOrders, (state, { value }): OngoingOrdersState => {
        const results = new Map(state.results);
        const key = getKey(value);
        const result = results.get(key);

        if (result) {
            return { ...state, loading: false, loaded: true, currentResult: result };
        }

        return { ...state, loading: true };
    }),
    on(ongoingOrdersActions.getOngoingOrdersSuccess, (state, { value, totalItems, items }): OngoingOrdersState => {
        const results = new Map(state.results);
        const key = getKey(value);
        const result: Result = { items, totalItems };

        results.set(key, result);

        return { ...state, loaded: true, loading: false, results, currentResult: result };
    }),
    on(ongoingOrdersActions.getOngoingOrdersError, (state): OngoingOrdersState => ({ ...state, loaded: false, loading: false })),
    on(ongoingOrdersActions.clear, (): OngoingOrdersState => ({ ...initialState, results: new Map() })),
);
