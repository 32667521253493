import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ONGOING_ORDERS_FEATURE_KEY } from './keys';
import { ongoingOrdersReducer } from './reducers/ongoing-orders.reducer';
import { OngoingOrdersEffects } from './effects/ongoing-orders.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(ONGOING_ORDERS_FEATURE_KEY, ongoingOrdersReducer), EffectsModule.forFeature([OngoingOrdersEffects])],
})
export class OngoingOrdersStoreModule {}
