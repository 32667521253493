import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OngoingOrdersState } from '../reducers/ongoing-orders.reducer';
import { ONGOING_ORDERS_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<OngoingOrdersState>(ONGOING_ORDERS_FEATURE_KEY);
const selectCurrentResult = createSelector(selectState, (state: OngoingOrdersState) => state.currentResult);

export const selectLoaded = createSelector(selectState, (state: OngoingOrdersState) => state.loaded);
export const selectLoading = createSelector(selectState, (state: OngoingOrdersState) => state.loading);
export const selectItems = createSelector(selectCurrentResult, (result) => result?.items ?? []);
export const selectTotalItems = createSelector(selectCurrentResult, (result) => result?.totalItems ?? null);
