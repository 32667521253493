import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, switchMap } from 'rxjs';
import { ongoingOrdersActions } from '../actions/ongoing-orders.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class OngoingOrdersEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getOngoingOrders$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ongoingOrdersActions.getOngoingOrders),
            switchMap(({ value }) => {
                return this.httpService.getOngoingOrders(value).pipe(
                    map(({ items, totalItems }) => ongoingOrdersActions.getOngoingOrdersSuccess({ items, totalItems, value })),
                    catchError(() => of(ongoingOrdersActions.getOngoingOrdersError())),
                );
            }),
        );
    });
}
