import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { OngoingOrdersFacade } from '../facades/ongoing-orders.facade';

export const ongoingOrdersCleanup: CanDeactivateFn<unknown> = () => {
    const ongoingOrdersFacade = inject(OngoingOrdersFacade);

    ongoingOrdersFacade.clear();
    return true;
};
