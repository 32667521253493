import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import { ApiListingResponse } from '@app/shared/models';
import { OngoingOrderDto, OngoingOrder, OngoingOrdersValueDto, OngoingOrdersValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getOngoingOrders(value: OngoingOrdersValue) {
        const { params } = new OngoingOrdersValueDto(value);

        return this.httpClient.get<ApiListingResponse<OngoingOrderDto[]>>('api/v1/ongoing-orders', { params }).pipe(
            map((response) => ({
                items: response.data.map((productDto) => new OngoingOrder(productDto)),
                totalItems: response.pagination.totalItems,
            })),
        );
    }
}
