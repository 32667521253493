import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OngoingOrder, OngoingOrdersValue } from '../models';

export const ongoingOrdersActions = createActionGroup({
    source: 'Ongoing Orders',
    events: {
        'Get Ongoing Orders': props<{ value: OngoingOrdersValue }>(),
        'Get Ongoing Orders Success': props<{ value: OngoingOrdersValue; items: OngoingOrder[]; totalItems: number }>(),
        'Get Ongoing Orders Error': emptyProps(),

        Clear: emptyProps(),
    },
});
